import { Box } from '@mui/system';
import React from "react";
import {Button} from "@mui/material";
import Typography from '@mui/material/Typography';

export default function LogoText() {
    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection={'column'}
        >
            <Typography variant="h6"
            color={'#FFFFFF'}>
                OurSpace
            </Typography>

        </Box>
    );
}