import { Box } from '@mui/system';
import React, { useState } from "react";
import {Button, Checkbox, FormControlLabel, TextField} from "@mui/material";
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Event } from '../../types/models/Event.model';

interface EventBoxProps {
    event: Event;
}

export default function EventBox({ event }: EventBoxProps) {
    const [open, setOpen] = useState(false);
    const [invitedUsers, setInvitedUsers] = useState<string[]>([]);

    const users = ['user@example.com', 'guest1@example.com', 'guest2@example.com'];
    const handleCheckboxChange = (email: string) => {
        if (invitedUsers.includes(email)) {
            setInvitedUsers(invitedUsers.filter(user => user !== email));
        } else {
            setInvitedUsers([...invitedUsers, email]);
        }
    };

    const handleSave = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget);
        console.log('Updated Event Data:', {
            name: formData.get('name'),
            location: formData.get('location'),
            guestList: invitedUsers,
        });
        setOpen(false);
    };

    return (
        <div>
            <Box>
                <Card sx={{ minWidth: 275, backgroundColor: '#FCF8F0' }} onClick={() => setOpen(true)} data-cy="event-card">
                    <CardContent>
                        <Typography sx={{ mb: 1.5 }} color="#FFFFFF" fontSize={'23px'}>
                            {event.eventName}
                        </Typography>
                        <Typography sx={{ fontSize: 19 }} color="text.secondary" gutterBottom>
                            {event.location}
                        </Typography>
                        <Typography sx={{ fontSize: 19 }} color="text.secondary" gutterBottom>
                            {new Date(event.date).toLocaleDateString()} {/* Format the date */}
                        </Typography>
                    </CardContent>
                </Card>
            </Box>

            {/* Dialog for editing the event */}
            <Dialog open={open} onClose={() => setOpen(false)}>
                <form onSubmit={handleSave}>
                    <DialogTitle>Edit Event</DialogTitle>
                    <DialogContent>
                        <TextField required name="name" label="Event Name" fullWidth margin="dense" defaultValue={event.eventName} />
                        <TextField required name="location" label="Location" fullWidth margin="dense" defaultValue={event.location} />

                        <div>
                            <h3>Invite Users</h3>
                            {users.map((user, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={invitedUsers.includes(user)}
                                            onChange={() => handleCheckboxChange(user)}
                                        />
                                    }
                                    label={user}
                                />
                            ))}
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)}>Cancel</Button>
                        <Button type="submit">Save</Button>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
