import { Box } from '@mui/system';
import logo from '../../logo1.png';
import LogoText from '../../components/atoms/LogoText';
import React from "react";
import {Button} from "@mui/material";
import AppBar from '../organisms/AppBar';
import LoginButton from '../atoms/LoginButton'

export default function HomePage() {
  return (
    <Box
      display='flex'
      alignItems='center'
      justifyContent='center'
      flexDirection={'column'}
    >
      <AppBar></AppBar>
      <Box       display='flex'
                 alignItems='center'
                 justifyContent='center'>
      <h2>Welcome to OurSpace. To use our services, please log in.</h2>
      </Box>
      <LoginButton></LoginButton>

    </Box>
  );
}
