enum authorities {
/*
  add your authorities here. E.g:
*/
  USER_CREATE = "USER_CREATE",
  USER_READ = "USER_READ",
  USER_DEACTIVATE = "USER_DEACTIVATE",

  EVENT_CREATE = "EVENT_CREATE",
  EVENT_READ = "EVENT_READ",
  EVENT_POST = "EVENT_POST",
  EVENT_UPDATE = "EVENT_UPDATE",
  EVENT_DELETE = "EVENT_DELETE",
}

export default authorities;
