import { Box } from '@mui/system';
import React, { useEffect, useState } from "react";
import { Grid, Typography } from "@mui/material";
import AppBar from '../organisms/AppBar';
import EventBox from '../organisms/EventBox';
import EventService, { EventPage } from '../../Services/EventService';
import { Event } from '../../types/models/Event.model';
import Pagination from "@mui/material/Pagination";
import "../organisms/Pageination.css"

export default function HomePage() {
    const [events, setEvents] = useState<Event[]>([]);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const itemsPerPage = 10;

    // Fetch events when page changes
    useEffect(() => {
        async function fetchEvents() {
            const eventService = new EventService();
            try {
                const response: EventPage | void = await eventService.getAllEvents(page - 1, itemsPerPage); // page-1 because backend might be 0-indexed
                if (response) {
                    setEvents(response.content);
                    setTotalPages(response.totalPages);
                } else {
                    console.error("No events returned from the backend");
                }
            } catch (error) {
                console.error("Error fetching events:", error);
            }
        }

        fetchEvents();
    }, [page]);

    // Handle page change
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <>
            <Box
                display='flex'
                alignItems='center'
                justifyContent='center'
                flexDirection={'column'}
            >
                <AppBar />

                {events.length > 0 ? (
                    <Grid container spacing={2}>
                        {events.map((event, index) => (
                            <Grid item xs={6} key={event.id}> {/* Using event.id as the key */}
                                <EventBox event={event} /> {/* Pass each event as a prop */}
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Typography variant="h6">No events available</Typography>
                )}
            </Box>
            <div className="pagination-container">
                <div className="pagination-footer">
                    <Pagination
                        count={totalPages} // Total pages from backend
                        page={page}
                        onChange={handleChange}
                        color="primary"
                    />
                </div>
            </div>
        </>
    );
}
