import { Box } from '@mui/system';
import React from "react";
import {Button} from "@mui/material";
import Typography from '@mui/material/Typography';
import LogoText from '../atoms/LogoText'

export default function AppBar() {
    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection={'column'}
            width={'97.8%'}
            height={'20px'}
            color={'#808080'}
            sx={{ p: 2, backgroundColor: '#808080' }}
        >
            <LogoText></LogoText>
        </Box>
    );
}