import axios, { AxiosError, AxiosResponse } from "axios";
//import { UUID } from "node:crypto";
import api from "../config/Api";
import {Event} from "../types/models/Event.model";

export type Authority = {
    id: string,
    name: string;
}

export type Role = {
    id: string,
    name: string,
    authorities: Set<Authority>
}

export type User = {
    id: string,
    first_name: string,
    last_name: string,
    email: string,
    roles: Set<Role>,
}


export type EventPage = {
    content: Event[],
    pageable: {
        pageNumber: number,
        pageSize: number
    },
    totalPages: number,
    totalElements: number,
    number: number,
    size: number,
    first: boolean,
    last: boolean,
    numberOfElements: number,
    empty: boolean
}

export default class EventService {
    async getAllEvents(page = 0, size = 10): Promise<EventPage | void> {
        return await api
            .get("/event", { params: { page, size } })
            .then((response: AxiosResponse<EventPage>) => {
                return response.data;
            })
            .catch((error: AxiosError): void => {
                console.error('Error fetching events:', error);
                throw error;
            });
    }

    async getEventById(eventId: string): Promise<Event | void> {
        return await api
            .get(`/event/${eventId}`)
            .then((response: AxiosResponse<Event>) => {
                return response.data;
            })
            .catch((error: AxiosError): void => {
                console.error('Error fetching event by ID:', error);
                throw error;
            });
    }

    async postEvent(newEventItem: Event): Promise<Event | void> {
        return await api
            .post(`/event/register`, newEventItem)
            .then((response: AxiosResponse<Event>) => {
                return response.data;
            })
            .catch((error: AxiosError): void => {
                console.error('Error posting event:', error);
                throw error;
            });
    }

    async updateEvent(newEventItem: Event, oldEventItem: Event): Promise<Event | void> {
        return await api
            .put(`/event/${oldEventItem.id}`, newEventItem)
            .then((response: AxiosResponse<Event>) => {
                return response.data;
            })
            .catch((error: AxiosError): void => {
                console.error('Error updating event:', error);
                throw error;
            });
    }

    async deleteEvent(eventID: string): Promise<void> {
        return await api
            .delete(`/event/${eventID}`)
            .then(() => {
                console.log('Event deleted successfully');
            })
            .catch((error: AxiosError): void => {
                console.error('Error deleting event:', error);
                throw error;
            });
    }
}

