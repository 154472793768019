import { Box } from '@mui/system';
import React from "react";
import {Button, createTheme, ThemeProvider} from "@mui/material";
import Typography from '@mui/material/Typography';
import {useNavigate} from "react-router-dom";

const theme = createTheme({
    palette: {
        primary: {
            main: '#c8c8c8',
        },
        secondary: {
            main: '#c8c8c8',
        },
    },
});

const LoginButton: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
            flexDirection={'column'}
        >
            <ThemeProvider theme={theme}>
            <Button variant={"contained"} onClick={() => navigate('/login')} size={"small"}>
                <Typography variant="body1"
                        color={'#FFFFFF'}>
                Login
            </Typography>
            </Button>
            </ThemeProvider>
        </Box>
    );
}

export default LoginButton;