import {useState, useEffect} from "react";
import { Event } from "../../../types/models/Event.model"
import {useNavigate} from "react-router-dom";
import {useFormik} from "formik";
import {object, string} from "yup";
import {
    Box, Button,
    Checkbox,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    SelectChangeEvent,
    TextField
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import {DatePicker, LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import axios from "axios";
import {User} from "../../../types/models/User.model";
import UserService from "../../../Services/UserService";
import moment, {Moment} from "moment";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import EventService from "../../../Services/EventService";

interface EventProps {
    event: Event;
    submitActionHandler: (values: Event) => void;
}


const CreateEventPage = () => {
    let event : Event = {
        id: '',
        eventName: '',
        date: '',
        location: '',
        creator: {
            id: '',
            email: '',
            firstName: '',
            lastName: '',
            roles: [],
        },
        guestList: [],
    };
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            id: event.id,
            eventName: event ? event.eventName : '',
            date: event ? event.date : '',
            location: event ? event.location : '',
            creator: event ? event.creator : {
                id: '',
                email: '',
                firstName: '',
                lastName: '',
                roles: [],
            },
            guestList: event ? event.guestList : [],
        },
        validationSchema: object({
            eventName: string().required().min(2).max(50),
            location: string().required().min(1).max(100)
        }),
        onSubmit: (values: Event) => {
            submitActionHandler(values);
        },
        enableReinitialize: true,
    })

    const [eventO, setEventO] = useState<Event>({
        id: '',
        eventName: '',
        date: '',
        location: '',
        creator: {
            id: '',
            email: '',
            firstName: '',
            lastName: '',
            roles: [],
        },
        guestList: [],

    });

    const [dateEvent, setDateEvent] = useState<Moment | null>(moment());
    const [timeEvent, setTimeEvent] = useState<Moment | null>(moment());

    const [usersList, setUsersList] = useState<User[]>([]);
    const [userSelectList, setUserSelectList] = useState<string[]>([]);

    function getUserFromUUID(userid : string): User {
        let found = usersList.find((element) => element.id === userid);
        if (found){
            return found;
        } else {
            return {
                id: "",
                lastName: "",
                firstName: "",
                email: "",
                roles: []
            };
        }
    }
    function getUserNameAndSurnameFromUUID(userid : string) {
        let found = usersList.find((element) => element.id === userid);
        if (found){
            return found.firstName + " " + found.lastName;
        } else {
            return "unknown";
        }
    }

    const handleChangeUser = (event: SelectChangeEvent<typeof userSelectList>) => {
        const {
            target: {value},
        } = event;
        setUserSelectList(
          typeof value === 'string' ? value.split(',') : value
        );
        };

    async function setupUserList(){
        let allUsers = await UserService.getAllUsers();
        setUsersList(allUsers.data);
    }
    useEffect(() => {
        setupUserList();
    }, []);


    function submitActionHandler(values: Event) {
        let submitUserArray: User[];
        submitUserArray = userSelectList.map((guest) => getUserFromUUID(guest));
        let timeonly = timeEvent!.toISOString().substring(11,19);
        let submitDateTime: string = dateEvent!.toISOString().substring(0, 11).concat(timeonly);
        values.date = submitDateTime;
        values.guestList = submitUserArray;
        values.creator = JSON.parse(localStorage.getItem("user") as string);

        const eventService = new EventService();

        eventService.postEvent(values);

    }

    return(
        <>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{ paddingTop: '15px' }}>
                    <TextField
                        id='eventName'
                        label='Event Name'
                        variant='outlined'
                        sx={{ paddingRight: '10px' }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.eventName && formik.errors.eventName)}
                        value={formik.values.eventName}
                    />
                    {formik.errors.eventName && formik.touched.eventName ? (
                        <div style={{color: 'red'}}>{formik.errors.eventName}</div>
                    ):null}
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DatePicker onChange={(newValue) => setDateEvent(newValue)} value={dateEvent} renderInput={(params) => <TextField {...params} />} />
                        <TimePicker onChange={(newValue) => setTimeEvent(newValue)} value={timeEvent} renderInput={(params) => <TextField {...params} />} />
                    </LocalizationProvider>
                    <TextField
                        id='location'
                        label='Location'
                        variant='outlined'
                        sx={{ paddingRight: '10px' }}
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        error={Boolean(formik.touched.location && formik.errors.location)}
                        value={formik.values.location}
                    />
                    <InputLabel id="guestList-label">Guestlist</InputLabel>
                    <Select
                        labelId="guestList-label"
                        id="guestList-select"
                        multiple
                        value={userSelectList}
                        onChange={handleChangeUser}
                        input={<OutlinedInput label="Tag" />}
                        renderValue={(selected) => selected.map((sel) => getUserNameAndSurnameFromUUID(sel)).join(',')}
                    >
                        {usersList.map( (user, Ukey)=> (
                            <MenuItem value={user.id} key={Ukey}>
                                <Checkbox checked={userSelectList.indexOf(user.id) > -1} />
                                <ListItemText primary={user.firstName + " " + user.lastName} />
                            </MenuItem>
                        ) )}
                    </Select>
                </Box>
                <div>
                    <Button
                        sx={{marginTop: '15px', marginRight: '10px'}}
                        variant='contained'
                        color='success'
                        type='submit'
                        disabled={!(formik.dirty && formik.isValid)}
                    >
                        Save
                    </Button>
                    <Button
                        sx={{ marginTop: '15px'}}
                        variant='contained'
                        color='error'
                        onClick={() => {
                            navigate('/');
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </form>
        </>
    )
};
export default CreateEventPage;
